.screen-note {
  .selectable-list {
    .list {
      max-height: 83vh;
      overflow-y: auto;

      .element {
        border-bottom: 1px solid;
        padding: 13px;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .active-element {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .selectable-list {
      .list {
        max-height: none;
        width: calc(100vw - 26px);
        height: calc(100vh - 150px);
        //max-width: 300px;
        //max-height: 500px;
        //width: 100vw;
        //height: 100vh;
        background-color: #222;
        overflow-x: hidden;
        position: fixed;
        //top: 0;
        //left: 0;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1050;
        display: none;
        //padding-bottom: 63px;

        .element {
          border-color: #333 !important;

          span {
            font-size: 1.2rem;
            color: #999 !important;
          }
        }
      }

      .active-element {
        border-bottom: 2px solid;
        margin-bottom: 13px;
        padding: 13px;
        cursor: pointer;
        display: block;
      }

      &.opened {
        .list {
          display: block;
        }

        &:after {
          content: '\A';
          position: fixed;
          width: 100vw;
          height: 100vh;
          top:0;
          left:0;
          background:rgba(0, 0, 0, 0.8);
          //opacity: 1;
          //transition: all 0.5s;
          //-webkit-transition: all 0.5s;
          //-moz-transition: all 0.5s;
        }
      }
    }
  }
}
