$defaultTransparency: 0.80;

.style-dark {
  .imd-col-1-7, .imd-col-xs-1-7 {
    flex: 0 0 14.285714286%;
    max-width: 14.285714286%;
  }

  @media screen and (min-width: 576px) {
    .imd-col-sm-1-7 {
      flex: 0 0 14.285714286%;
      max-width: 14.285714286%;
    }
  }

  @media screen and (min-width: 768px) {
    .imd-col-md-1-7 {
      flex: 0 0 14.285714286%;
      max-width: 14.285714286%;
    }
  }

  @media screen and (min-width: 992px) {
    .imd-col-lg-1-7 {
      flex: 0 0 14.285714286%;
      max-width: 14.285714286%;
    }
  }

  @media screen and (min-width: 1200px) {
    .imd-col-xl-1-7 {
      flex: 0 0 14.285714286%;
      max-width: 14.285714286%;
    }
  }

  @each $name, $color in (main: #ff6400, default: #111111, contrast: #ffffff, accent: #9a9a9a, imid: #5299e8) {
    .imd-bg-#{$name} {
      background: $color;
    }

    .imd-bg-80-#{$name} { background: mix($color, white, 80%); }
    .imd-bg-60-#{$name} { background: mix($color, white, 60%); }
    .imd-bg-40-#{$name} { background: mix($color, white, 40%); }
    .imd-bg-20-#{$name} { background: mix($color, white, 20%); }
    .imd-bg-120-#{$name} { background: mix($color, black, 80%); }
    .imd-bg-140-#{$name} { background: mix($color, black, 60%); }
    .imd-bg-160-#{$name} { background: mix($color, black, 40%); }
    .imd-bg-180-#{$name} { background: mix($color, black, 20%); }

    .imd-bg-before-#{$name} {
      &::before {
        background-color: $color;
      }
    }

    .imd-bg-transparent-#{$name} { background: rgba($color, $defaultTransparency); }

    .imd-bg-hover-#{$name} {
      &:hover {
        background-color: $color;
      }
    }
    .imd-bg-hover-80-#{$name} { &:hover {background: mix($color, white, 80%); } }
    .imd-bg-hover-60-#{$name} { &:hover {background: mix($color, white, 60%); } }
    .imd-bg-hover-40-#{$name} { &:hover {background: mix($color, white, 40%); } }
    .imd-bg-hover-20-#{$name} { &:hover {background: mix($color, white, 20%); } }
    .imd-bg-hover-120-#{$name} { &:hover {background: mix($color, black, 80%); } }
    .imd-bg-hover-140-#{$name} { &:hover {background: mix($color, black, 60%); } }
    .imd-bg-hover-160-#{$name} { &:hover {background: mix($color, black, 40%); } }
    .imd-bg-hover-180-#{$name} { &:hover {background: mix($color, black, 20%); } }

    .imd-text-#{$name} {
      color: $color !important;

      &:hover {
        color: $color;
      }
    }
    .imd-text-80-#{$name} { color: mix($color, white, 80%) !important; &:hover { color: mix($color, white, 80%); } }
    .imd-text-60-#{$name} { color: mix($color, white, 60%) !important; &:hover { color: mix($color, white, 60%); } }
    .imd-text-40-#{$name} { color: mix($color, white, 40%) !important; &:hover { color: mix($color, white, 40%); } }
    .imd-text-20-#{$name} { color: mix($color, white, 20%) !important; &:hover { color: mix($color, white, 20%); } }
    .imd-text-120-#{$name} { color: mix($color, black, 80%) !important; &:hover { color: mix($color, black, 80%); } }
    .imd-text-140-#{$name} { color: mix($color, black, 60%) !important; &:hover { color: mix($color, black, 60%); } }
    .imd-text-160-#{$name} { color: mix($color, black, 40%) !important; &:hover { color: mix($color, black, 40%); } }
    .imd-text-180-#{$name} { color: mix($color, black, 20%) !important; &:hover { color: mix($color, black, 20%); } }

    .imd-border-color-#{$name} {
      border-color: $color !important;

      tr, th, td {
        border-color: $color !important;
      }
    }

    .imd-border-color-80-#{$name} { border-color: mix($color, white, 80%) !important; * { border-color: mix($color, white, 80%); } }
    .imd-border-color-60-#{$name} { border-color: mix($color, white, 60%) !important; * { border-color: mix($color, white, 60%); } }
    .imd-border-color-40-#{$name} { border-color: mix($color, white, 40%) !important; * { border-color: mix($color, white, 40%); } }
    .imd-border-color-20-#{$name} { border-color: mix($color, white, 20%) !important; * { border-color: mix($color, white, 20%); } }
    .imd-border-color-120-#{$name} { border-color: mix($color, black, 80%) !important; * { border-color: mix($color, black, 80%); } }
    .imd-border-color-140-#{$name} { border-color: mix($color, black, 60%) !important; * { border-color: mix($color, black, 60%); } }
    .imd-border-color-160-#{$name} { border-color: mix($color, black, 40%) !important; * { border-color: mix($color, black, 40%); } }
    .imd-border-color-180-#{$name} { border-color: mix($color, black, 20%) !important; * { border-color: mix($color, black, 20%); } }

    .imd-section-glass-#{$name} { border-top-color: rgba($color, $defaultTransparency) !important; }

    .imd-placeholder-#{$name} {
      &::placeholder {
        color: $color;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $color;
      }

      &::-ms-input-placeholder {
        color: $color;
      }
    }
  }
}
