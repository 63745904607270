h1.header {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  background: linear-gradient(131deg, #ff4600, #ff9066 50%, #ffdacc);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 1s ease-out 0s 1 fadeInText;
}