$color: #ff4600, #ff6b33, #ff9066, #ffb599, #ffdacc;

.preloader {
  &.listening {
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    $circle-size: 20px;
    $circle-margin: 7px;

    .circle {
      width: $circle-size;
      height: $circle-size;
      border-radius: 50%;
      margin: $circle-margin;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: "";
        width: $circle-size;
        height: $circle-size;
        border-radius: 50%;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
      }
    }

    @for $i from 1 through 5 {
      .circle-#{$i} {
        background-color: nth($color, $i);
        &:before {
          background-color: nth($color, $i);
          animation-delay: 200ms * $i;
        }
      }
    }

    @keyframes scale {
      0% {
        transform: scale(1);
      }
      50%,
      75% {
        transform: scale(2.5);
      }
      78%,
      100% {
        opacity: 0;
      }
    }
  }
}
