#screen-main {
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  overflow-y: auto;
  padding-bottom: 113px;

  .hello, .query {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(131deg, #ff4600, #ff9066 50%, #ffdacc);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: 1s ease-out 0s 1 fadeInText;
  }

  .answer {
    max-width: 75vw;
    margin: 30px auto;
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    animation: 1s ease-out 0s 1 fadeInText;

    + div {
      .answer-html {
        .answer-html-title {
          font-size: 1.8rem !important;
          font-weight: 700;
          background: linear-gradient(131deg, #ff4600, #ff9066 50%, #ffdacc);
          background-clip: border-box;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: 1s ease-out 0s 1 fadeInText;
        }
      }
    }
  }

  .query-block {
    background: rgba(22, 22, 22, 0.8);
    backdrop-filter: saturate(180%) blur(7px);
  }

  @keyframes fadeInText {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .talk {
    text-align: left;
    //height: calc(100vh - 180px);
    overflow-y: auto;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .message {
      > div {
        &.t-query {
          max-width: 100%;
          text-align: right;

          > div {
            border: 1px solid;
          }
        }

        > div {
          width: auto;
          max-width: 100%;
          border-radius: 13px;
          margin-bottom: 26px;
          padding: 13px;
          display: inline-block;
        }

        &.t-answer {
          > div {
            border: 1px solid;

            > div {
              max-width: 100%;
              overflow: auto;
            }
          }
        }
      }
    }
  }
}