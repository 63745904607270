#login {
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;

  .logo {
    width: 250px;
    height: 250px;
    margin-top: 50px;
    margin-bottom: 75px;
    text-align: center;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    //.andra {
    //  width: 120px;
    //  height: 120px;
    //  font-size: 10px;
    //}
  }

  button {
    font-size: 14.3pt;
    padding-bottom: 8px;
  }

  .imid-logo {
    width: 30px;
    height: 15px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAPCAYAAADzun+cAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QMIFToiefzOvgAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAABVUlEQVQ4y6XVPUsdYRCG4VmDX8GgIEoqCUkh2qWwS2Mhtv4BKw1pxCaQxj5oIViJ2AQC/oZ0acQyVQIBsUgjaAJiBIPgx5Ui74HjS3bP7vFpFpaZ594ZZmYjKoTHWOkQM441TKAnvYsHCQv+6U2ZYQL/xk+8x/NWbOMPSEmD+JTApy3D/8SO4TjF3aXYtXav2tD0nHdf6yhyowzcri+YQm8T6AguMqMTvMirqADDFXbxslYHsFditNOh1WX6he1KOF6lYSnTdNadOuCWzjGL/nygevGhQ/I3DDSsONdiXu10jaQbrHYJ/oE5PMkH5WtNg+8YbwC+xGbZJL9r2K63bQfkuAL4sXSqMZnWpamGMVoC3scM+spW51E6dbddgD9jKAOf43XHvcWzdGe70TWWcJZ2dQtP6xyLAhsRsRwRf7r5j0TEUUQcRMReRBwWRXGHKIqiMvEvofAfzwXXBhIAAAAASUVORK5CYII=') no-repeat 0 0;
    margin-right: 5px;
    display: inline-block;
  }

  .license {
    text-align: center;
    margin-top: 13px;
    font-size: 0.8rem;
    display: block;

    button {
      border: 0;
      background: none;
      text-decoration: underline;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }

  .madeby-mateusz-kolasa {
    width: 100vw;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 50px;

    img {
      width: 300px;
      max-width: 90vw;
      display: block;
      margin: 0 auto 13px auto;
    }
  }
}

.rtl {
  #login {
    .logo {
      right: 50%;
      -webkit-transform: translateX(50%);
      -moz-transform: translateX(50%);
      -ms-transform: translateX(50%);
      -o-transform: translateX(50%);
      transform: translateX(50%);
    }

    .language-selector {
      right: 13px !important;
      left: auto !important;
    }
  }
}
