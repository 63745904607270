#settings {
  .account {
    width: 100%;
    border: 1px solid;
    padding: 13px;
    margin-bottom: 13px;

    .imd-label {
      float: right;
    }

    h3 {
      margin: 0 0 13px 0;
    }

    strong {
      margin: 4px 0;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin-right: 13px;
      float: left;
    }

    .imid-logo {
      width: auto;
      height: 30px;
      float: none;
    }
  }


  .madeby-mateusz-kolasa {
    width: 100vw;
    color: #fff;
    //text-align: center;
    margin-top: 26px;

    p {
      //margin: 0 0 0 75px;
    }

    a {
      display: inline-block;
    }

    img {
      width: 200px;
      max-width: 90vw;
      display: block;
      margin: 0 0 13px 0;
    }
  }
}
