body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #333;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.imd-nav-icon {
  border: 0;
}

/* Fix for chart sizes */
.recharts-surface {
  width: auto !important;
  height: 250px !important;
  margin: 20px 0 0 -15px;
}