.language {
  width: 150px;

  .submenu {
    width: 150px;
    max-height: 200px;
    border-radius: 7px;
    display: inline-block;
    margin-top: 13px;
    padding: 0;
    overflow-y: auto;
    position: relative;

    ul {
      list-style-type: none;
      text-align: left;
      padding: 0;
      margin: 0;

      li {
        padding: 7px 13px;
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.1)
        }
      }
    }
  }
}

//.rtl {
//  .language {
//    direction: ltr;
//  }
//}
