#screen-recipes {
  button {
    margin-right: 7px;
  }

  .list {
    padding-top: 26px;

    .recipe {
      padding: 14px;
      margin-bottom: 7px;
      cursor: pointer;
    }
  }
}
