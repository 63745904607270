.screen {
  width: 100vw;
  max-height: 100vh;
  padding: 63px 13px 13px 13px;
  position: relative;
  z-index: 1;

  h1 {
    font-size: 1.5rem;
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 65px);
  }

  .actions {
    text-align: left;

    button {
      margin-right: 13px;
    }
  }
}
