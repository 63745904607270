.finance {
  &-category {
    display: inline-block;
    margin: 3px;

    span {
      font-size: 0.8em;
      margin-left: 2px;
      cursor: pointer;
    }
  }
}
