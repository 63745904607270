#screen-nyumba {
  .container {
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .device-name {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    background: linear-gradient(131deg, #ff4600, #ff9066 50%, #ffdacc);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: 1s ease-out 0s 1 fadeInText;
  }

  .device {
    display: flex;
    align-items: center;

    .icon-container {
      .icon {
        width: 65px;
        font-size: 50px;
        float: left;
      }
    }

    > .properties-container {
      width: calc(100% - 65px);

      .name, .id {
        max-width: 100%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .id {
        font-size: 0.8rem;
        display: inline-block;
      }

      .actions {
        height: 1.5rem;
      }
    }
  }

  @keyframes fadeInText {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}