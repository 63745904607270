#screen-auto {
  .alert {
    display: block;
    font-size: 1.5rem;
  }

  .content {
    display: none;
  }

  @media screen and (orientation: landscape) {//} and (hover: none) and (pointer: coarse) {
    .alert {
      display: none;
    }

    .content {
      display: block;
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    background: linear-gradient(131deg, #ff4600, #ff9066 50%, #ffdacc);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: 1s ease-out 0s 1 fadeInText;
  }
}