/* /!\ You need to add vendor prefixes in order to render the CSS properly (or simply use http://leaverou.github.io/prefixfree/) /!\ */
.andra {
    /*perspective: 500px;*/
    position: relative;
    overflow: hidden;
    background: transparent;
    font-size: 3px;

    .scene, .shape, .face, .face-wrapper, .cr {
        position: absolute;
        transform-style: preserve-3d;
    }
    .scene {
        top: 50%;
        left: 50%;
    }
    .shape {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform-origin: 50%;
    }
    .face, .face-wrapper {
        overflow: hidden;
        transform-origin: 0 0;
        backface-visibility: hidden;
        /* hidden by default, prevent blinking and other weird rendering glitchs */
    }
    .face {
        background-size: 100% 100%!important;
        background-position: center;
    }
    .face-wrapper .face {
        left: 100%;
        width: 100%;
        height: 100%
    }
    .photon-shader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%
    }
    .side {
        left: 50%;
    }
    .cr, .cr .side {
        height: 100%;
    }
    [class*="pyramid"] .face-wrapper .face {
        transform: rotateZ(45deg) translateX(-35.35%) translateY(35.35%);
    }
    [class*="pyramid"] .bk {
        left: 100%;
    }
    [class*="pyramid"] .bm {
        top: 100%;
        transform: rotateX(-90deg) translateY(-50%);
    }
    [class*="pyramid"] .rt, [class*="pyramid"] .lt {
        transform-origin: 50% 0;
    }
    /* .pyr-1 styles */
    .pyr-1 {
        opacity:1;
    }
    .pyr-1 .ft {
        transform:scaleZ(2.692582403567252) scaleY(2.692582403567252) rotateX(21.801409486351798deg);
    }
    .pyr-1 .bk {
        transform:scaleZ(2.692582403567252) scaleY(2.692582403567252) rotateX(-21.801409486351798deg) rotateY(180deg);
    }
    .pyr-1 .rt {
        transform:scaleX(2.692582403567252) scaleY(2.692582403567252) rotateZ(21.801409486351798deg) rotateY(-90deg);
    }
    .pyr-1 .lt {
        transform:scaleX(2.692582403567252) scaleY(2.692582403567252) rotateZ(-21.801409486351798deg) rotateY(90deg);
    }
    ///* .pyr-2 styles */
    .pyr-2 {
        opacity:1;
    }
    .pyr-2 .ft {
        transform:scaleZ(2.692582403567252) scaleY(2.692582403567252) rotateX(21.801409486351798deg);
    }
    .pyr-2 .bk {
        transform:scaleZ(2.692582403567252) scaleY(2.692582403567252) rotateX(-21.801409486351798deg) rotateY(180deg);
    }
    .pyr-2 .rt {
        transform:scaleX(2.692582403567252) scaleY(2.692582403567252) rotateZ(21.801409486351798deg) rotateY(-90deg);
    }
    .pyr-2 .lt {
        transform:scaleX(2.692582403567252) scaleY(2.692582403567252) rotateZ(-21.801409486351798deg) rotateY(90deg);
    }
    /* .pyr-3 styles */
    .pyr-3 {
        opacity:1;
    }
    .pyr-3 .ft {
        transform:scaleZ(2.4948927423675684) scaleY(2.4948927423675684) rotateX(23.62937773065681deg);
    }
    .pyr-3 .bk {
        transform:scaleZ(2.4948927423675684) scaleY(2.4948927423675684) rotateX(-23.62937773065681deg) rotateY(180deg);
    }
    .pyr-3 .rt {
        transform:scaleX(2.4948927423675684) scaleY(2.4948927423675684) rotateZ(23.62937773065681deg) rotateY(-90deg);
    }
    .pyr-3 .lt {
        transform:scaleX(2.4948927423675684) scaleY(2.4948927423675684) rotateZ(-23.62937773065681deg) rotateY(90deg);
    }
    /* .pyr-4 styles */
    .pyr-4 {
        opacity:1;
    }
    .pyr-4 .ft {
        transform:scaleZ(2.5385910352879697) scaleY(2.5385910352879697) rotateX(23.198590513648202deg);
    }
    .pyr-4 .bk {
        transform:scaleZ(2.5385910352879697) scaleY(2.5385910352879697) rotateX(-23.198590513648202deg) rotateY(180deg);
    }
    .pyr-4 .rt {
        transform:scaleX(2.5385910352879697) scaleY(2.5385910352879697) rotateZ(23.198590513648202deg) rotateY(-90deg);
    }
    .pyr-4 .lt {
        transform:scaleX(2.5385910352879697) scaleY(2.5385910352879697) rotateZ(-23.198590513648202deg) rotateY(90deg);
    }
    /* .pyr-5 styles */
    .pyr-5 {
        opacity:1;
    }
    .pyr-5 .ft {
        transform:scaleZ(2.692582403567252) scaleY(2.692582403567252) rotateX(21.801409486351798deg);
    }
    .pyr-5 .bk {
        transform:scaleZ(2.692582403567252) scaleY(2.692582403567252) rotateX(-21.801409486351798deg) rotateY(180deg);
    }
    .pyr-5 .rt {
        transform:scaleX(2.692582403567252) scaleY(2.692582403567252) rotateZ(21.801409486351798deg) rotateY(-90deg);
    }
    .pyr-5 .lt {
        transform:scaleX(2.692582403567252) scaleY(2.692582403567252) rotateZ(-21.801409486351798deg) rotateY(90deg);
    }

    /* Animations */
    .pyr-1, .pyr-2, .pyr-3, .pyr-4, .pyr-5 {
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    /* Animation 1 */
    @keyframes push1 {
        0% {
            transform:translate3D(0em, 2.5em, 0em) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            scale(1.0);
        }
        7% {
            transform:translate3D(0em, 2.5em, 0em) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            scale(1.2);
        }
        15% {
            transform:translate3D(0em, 2.5em, 0em) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            scale(1.0);
        }
    }

    //.pyr-1 {
    //    animation-name: push1;
    //    animation-duration: 2s;
    //    animation-delay: 3s;
    //}
    //
    ///* Animation 2 */
    //@keyframes push2 {
    //    0% {
    //        transform:translate3D(2em, -2em, 0em) rotateX(0deg) rotateY(360deg) rotateZ(-135deg)
    //        scale(1.0);
    //    }
    //    7% {
    //        transform:translate3D(2em, -2em, 0em) rotateX(0deg) rotateY(360deg) rotateZ(-135deg)
    //        scale(1.2);
    //    }
    //    15% {
    //        transform:translate3D(2em, -2em, 0em) rotateX(0deg) rotateY(360deg) rotateZ(-135deg)
    //        scale(1.0);
    //    }
    //}
    //
    //.pyr-2 {
    //    animation-name: push2;
    //    animation-duration: 2s;
    //    animation-delay: 3s;
    //}
    //
    ///* Animation 3 */
    //@keyframes push3 {
    //    0% {
    //        transform:translate3D(0em, 0em, 2.1em) rotateX(90deg) rotateY(0deg) rotateZ(0deg)
    //        scale(1.0);
    //    }
    //    7% {
    //        transform:translate3D(0em, 0em, 2.1em) rotateX(90deg) rotateY(0deg) rotateZ(0deg)
    //        scale(1.2);
    //    }
    //    15% {
    //        transform:translate3D(0em, 0em, 2.1em) rotateX(90deg) rotateY(0deg) rotateZ(0deg)
    //        scale(1.0);
    //    }
    //}
    //
    //.pyr-3 {
    //    animation-name: push3;
    //    animation-duration: 3s;
    //    animation-delay: 2s;
    //}
    //
    ///* Animation 4 */
    //@keyframes push4 {
    //    0% {
    //        transform:translate3D(-1em, -1.5em, -1em) rotateX(0deg) rotateY(-45deg) rotateZ(135deg)
    //        scale(1.0);
    //    }
    //    7% {
    //        transform:translate3D(-1em, -1.5em, -1em) rotateX(0deg) rotateY(-45deg) rotateZ(135deg)
    //        scale(1.2);
    //    }
    //    15% {
    //        transform:translate3D(-1em, -1.5em, -1em) rotateX(0deg) rotateY(-45deg) rotateZ(135deg)
    //        scale(1.0);
    //    }
    //}
    //
    //.pyr-4 {
    //    animation-name: push4;
    //    animation-duration: 3s;
    //    animation-delay: 2s;
    //}
    //
    ///* Animation 5 */
    //@keyframes push5 {
    //    0% {
    //        transform:translate3D(0.5em, 0.5em, -1em) rotateX(0deg) rotateY(60deg) rotateZ(-60deg)
    //        scale(1.0);
    //    }
    //    7% {
    //        transform:translate3D(0.5em, 0.5em, -1em) rotateX(0deg) rotateY(60deg) rotateZ(-60deg)
    //        scale(1.2);
    //    }
    //    15% {
    //        transform:translate3D(0.5em, 0.5em, -1em) rotateX(0deg) rotateY(60deg) rotateZ(-60deg)
    //        scale(1.0);
    //    }
    //}
    //
    //.pyr-5 {
    //    animation-name: push5;
    //    animation-duration: 5s;
    //}
}
