#screen-calendars {
  .content {
    padding-bottom: 50px;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    background: linear-gradient(131deg, #ff4600, #ff9066 50%, #ffdacc);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: 1s ease-out 0s 1 fadeInText;
  }

  .event {
    border: 1px solid;
    padding: 13px;
    margin-bottom: 7px;
    cursor: pointer;
  }

  .calendar-color {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.6rem;
    display: inline-block;
    margin: 0 3px -0.2rem 0;
  }

  .calendar-color.selector {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.9rem;
    cursor: pointer;
    margin: 0 3px 0 0;
  }

  .calendar-color.selector.active {
    border: 4px solid;
  }
}
