#main-panel {
  .fake-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 50;
  }

  @keyframes main-menu {
    0% {
      left: -300px;
    }
    100% {
      left: 0;
    }
  }

  @keyframes main-menu-close {
    0% {
      left: 0;
    }
    100% {
      left: -300px;
    }
  }

  .imd-nav-button {
    position: fixed;
    top: 7px;
    left: 0;
    z-index: 51;

    border: 0;
    background: none;
    padding: 13px;
    cursor: pointer;

    .imd-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      border: 1px solid #fff;
      background: #fff;

      -webkit-transition: opacity, -webkit-transform;
      -moz-transition: opacity, -webkit-transform;
      -o-transition: opacity, -webkit-transform;
      transition: opacity, transform, -webkit-transform;
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);
      -moz-transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);
      -o-transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);
      transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);

      + .imd-bar {
        margin-top: 6px;
      }
    }

    &.opened{
      .imd-bar:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(45deg);
        -moz-transform: translateY(8px) rotate(45deg);
        -o-transform: translateY(8px) rotate(45deg);
        transform: translateY(8px) rotate(45deg);
      }

      .imd-bar:nth-child(2) {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
      }

      .imd-bar:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(-45deg);
        -moz-transform: translateY(-8px) rotate(-45deg);
        -o-transform: translateY(-8px) rotate(-45deg);
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  .notification-button {
    position: fixed;
    top: 7px;
    left: 40px;
    z-index: 51;
    border: 0;
    background: none;
    padding: 13px;
    cursor: pointer;

    .imd-icon {
      font-size: 20px;
    }

    .counter {
      width: 15px;
      height: 15px;
      border-radius: 8px;
      background: #d81414;
      color: #fff;
      font-size: 0.7rem;
      display: inline-block;
      position: relative;
      top: 3px;
      left: -9px;
    }
  }

  .menu {
    min-width: 300px;
    max-width: 100vw;
    height: 100vh;
    position: fixed;
    padding: 63px 0 13px 0;
    z-index: 50;
    left: 0;
    transform: translateX(-100%);
    transition: opacity, transform, -webkit-transform;
    transition-duration: 0.2s;

    &.opened {
      transform: translateX(0%);
    }

    ul, li {
      list-style: none;
      padding: 0;
    }

    li {
      padding: 13px;
      cursor: pointer;

      &.active {
        background: #ffffff33;
      }
    }

    .imd-icon {
      margin-right: 3px;
    }

    &.notifications {
      width: 300px;

      > div {
        height: calc(100vh - 100px);
        overflow-y: auto;
        padding: 0 13px;
        margin-top: 30px;

        button {
          margin-top: -3px;
        }
      }
    }
  }

  .imid {
    margin-right: 13px;
    position: relative;
    top: 0;

    > div {
      width: 40px;
      height: 40px;
      border-radius: 20px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
    }
  }
}
