@function multiple-box-shadow($n, $color) {
  $value: #{random(2000)}px #{random(2000)}px $color;

  @for $i from 2 through $n {
    $value: #{$value}, #{random(2000)}px #{random(2000)}px $color;
  }

  @return $value;
}

$shadows-small:  multiple-box-shadow(700, #777);
$shadows-medium: multiple-box-shadow(200, #777);
$shadows-big:    multiple-box-shadow(100, #444);

@keyframes animStar {
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(-2000px)
  }
}

#background {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #191919 0%, #111111 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  #stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
    animation: animStar 50s linear infinite;

    &:after {
      content: " ";
      position: absolute;
      top: 2000px;
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: $shadows-small;
    }
  }

  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
    animation: animStar 100s linear infinite;

    &:after {
      content: " ";
      position: absolute;
      top: 2000px;
      width: 2px;
      height: 2px;
      background: transparent;
      box-shadow: $shadows-medium;
    }
  }

  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
    animation: animStar 150s linear infinite;

    &:after {
      content: " ";
      position: absolute;
      top: 2000px;
      width: 3px;
      height: 3px;
      background: transparent;
      box-shadow: $shadows-big;
    }
  }
}

.rtl {
  #background {
    direction: ltr;
  }
}